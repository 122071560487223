import ReactMarkdown from 'react-markdown';
import cx from 'classnames';

export const MarkdownTemplate = (props) => {
  const container = cx(
    "-mt-3 [&>h1]:font-bold text-sm md:text-base [&>h1]:mt-3 [&>p]:mt-3",
    "[&_li]:mt-3 [&_li>ul]:ml-12",
    "before:[&_li]:content-[''] before:[&_li]:inline-block before:[&_li]:w-3 before:[&_li]:md:w-9 before:[&_li]:h-1 before:[&_li]:bg-[#40A054] before:[&_li]:rounded-lg",
    "before:[&_li]:mr-3 before:[&_li]:mb-[3px]",
  );

  return (
    <div className={container}>
      <ReactMarkdown>
        {props.children}
      </ReactMarkdown>
    </div>
  );
};
