import { useRef } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

const DisclosureTemplate = (props) => {
  const ref = useRef(null);
  const refDown = useRef(null);
  const refUp = useRef(null);
  const refTitle = useRef(null);

  const handleClick = () => {
    if (!ref.current || !refDown.current || !refUp.current || !refTitle.current) return null;

    if (ref.current.style.display === "none") {
      ref.current.style.display = "block";
    } else {
      ref.current.style.display = "none";
    }

    if (refUp.current.style.display === "none") {
      refUp.current.style.display = "block";
      refTitle.current.style.color = "#40A054";
    } else {
      refUp.current.style.display = "none"
      refTitle.current.style.color = "black";
    }

    if (refDown.current.style.display === "none") {
      refDown.current.style.display = "block";
      refTitle.current.style.color = "black";
    } else {
      refDown.current.style.display = "none";
      refTitle.current.style.color = "#40A054";
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center hover:cursor-pointer" onClick={handleClick}>
        <div ref={refTitle} style={{color: "black"}}>
          {props.DisclosureTitleComponent}
        </div>
        {props.DisclosureBodyComponent ? (
          <span ref={refDown} style={{display: "block", color: "#40A054"}}>
            <FaAngleDown/>
          </span>
        ) : null}

        <span ref={refUp} style={{display: "none", color: "#40A054"}}>
          <FaAngleUp/>
        </span>
      </div>

      {props.DisclosureBodyComponent ? (
        <div ref={ref} style={{display: "none"}}>
          {props.DisclosureBodyComponent}
        </div>
      ) : null}
    </div>
  );
};

export default DisclosureTemplate;
