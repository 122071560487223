import { useEffect, useState } from 'react';
import SidebarBody from './SidebarBody';
import { useHref } from 'react-router';

const SidebarTemplate = (props) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const href = useHref();

  useEffect(() => {
    if (filterOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [filterOpen]);

  useEffect(() => {
    if (filterOpen) {
      setFilterOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href])
  return (
    <div>
      <div onClick={() => setFilterOpen(true)} className="hover:cursor-pointer">
        {props.IconComponent}
      </div>

      {filterOpen ? (
        <>
          <div className="fixed inset-0 bg-gray-400 opacity-20 overflow-y-scroll z-20"></div>
          <SidebarBody
            setFilterOpen={setFilterOpen}
            SidebarBodyComponent={props.SidebarBodyComponent}
            position={props.position}
          />
        </>
      ) : null}
    </div>
  );
};

export default SidebarTemplate;