import { createBrowserRouter, RouterProvider, Outlet, redirect } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import HeaderComponent from './src/components/Header';
import HeaderInfoComponent from './src/components/HeaderInfo';
import HeaderNavigationComponent from './src/components/HeaderNavigation';
import LandingBannerComponent from './src/components/LandingBanner';
import LandingStatsComponent from './src/components/LandingStats';
import LandingCalculationsComponent from './src/components/LandingCalculations';
import LandingInfoComponent from './src/components/LandingInfo';
import LandingServicesComponent from './src/components/LandingServices';
import LandingQuestionsComponent from './src/components/LandingQuestions';
import FooterComponent from './src/components/Footer';
import ProcutsComponent from './src/components/Products';
import LandingContactComponent from './src/components/LandingContact';
import LandingProductCalculationComponent from './src/components/LandingProductCalculation';
import LandingProductsCalculationComponent from './src/components/LandingProductsCalculation';
import ProductBannerComponent from './src/components/ProductBanner';
import ProductComponent from './src/components/Product';
import QuestionsComponent from './src/components/Questions';
import AboutComponent from './src/components/About';
import i18n from './i18n';

function Root() {
  const lang = i18n.language;
  const { locale } = useParams();
  if (!locale) {
    i18n.changeLanguage("tm")
  }

  if (lang !== locale) {
    i18n.changeLanguage(locale)
  }

  return (
    <Outlet/>
  );
};

function App() {
  const router = createBrowserRouter([
    {
      path: ":locale?",
      element: <Root />,
      loader: ({params}) => {
        if (params.locale === "tm") {
          return redirect("/")
        } 
        if (params.locale && params.locale !== "en" && params.locale !== "ru") {
          return redirect("/404")
        }
        return null;
      },
      children: [
        {
          path: "",
          element: (
            <div className="h-screen flex flex-col">
              <HeaderInfoComponent />
              <HeaderComponent />
              <HeaderNavigationComponent />
              <LandingBannerComponent />
              <LandingStatsComponent />
              <LandingCalculationsComponent />
              <LandingInfoComponent />
              <LandingServicesComponent />
              <LandingQuestionsComponent />
              <FooterComponent />
            </div>
          ),
        },
        {
          path: "products",
          element: (
            <div className="h-screen flex flex-col">
              <HeaderInfoComponent />
              <HeaderComponent />
              <HeaderNavigationComponent />
              <ProcutsComponent />
              <FooterComponent />
            </div>
          ),
        },
        {
          path: "product/:productId",
          element: (
            <div className="h-screen flex flex-col">
              <HeaderInfoComponent />
              <HeaderComponent />
              <HeaderNavigationComponent />
              <ProductBannerComponent />
              <ProductComponent />
              <LandingProductCalculationComponent />
              <LandingQuestionsComponent />
              <FooterComponent />
            </div>
          ),
        },
        {
          path: "calculator",
          element: (
            <div className="h-screen flex flex-col">
              <HeaderInfoComponent />
              <HeaderComponent />
              <HeaderNavigationComponent />
              <LandingProductsCalculationComponent />
              <FooterComponent />
            </div>
          ),
        },
        {
          path: "questions",
          element: (
            <div className="h-screen flex flex-col">
              <HeaderInfoComponent />
              <HeaderComponent />
              <HeaderNavigationComponent />
              <QuestionsComponent />
              <FooterComponent />
            </div>
          ),
        },
        {
          path: "about",
          element: (
            <div className="h-screen flex flex-col">
              <HeaderInfoComponent />
              <HeaderComponent />
              <HeaderNavigationComponent />
              <AboutComponent />
              <FooterComponent />
            </div>
          ),
        },
        {
          path: "contact",
          element: (
            <div className="h-screen flex flex-col">
              <HeaderInfoComponent />
              <HeaderComponent />
              <HeaderNavigationComponent />
              <LandingContactComponent />
              <FooterComponent />
            </div>
          ),
        },
      ]
    },
    {
      path: "404",
      element: <p>404 Page</p>
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
