import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { BoxShadowTemplate } from '../../templates/BoxShadow';
import { SectionTitleTemplate } from '../../templates/Section/Title';
import { useTranslation } from 'react-i18next';
import { CarouselArrow } from '../../templates/CarouselSlick/CarouselArrow';
import { InfoImageHandlerTemplate } from '../../templates/InfoImageHandler';

function LandingInfo() {
  const { t } = useTranslation("translation", {keyPrefix: "main.info"});
  return (
    <div className="p-3 py-12">
      <LayoutContainerHorizontalTemplate>
        <LayoutSpacerVerticalTemplate size="large">
          <LayoutPaddingHorizontalTemplate size="xlarge">
            <SectionTitleTemplate title={t("title")} className="self-start" />
          </LayoutPaddingHorizontalTemplate>

          <div className='flex flex-col px-8 md:px-20 lg:px-28'>
            <CarouselArrow>
              {
                t("items", {returnObjects: true}).map((item, index) =>
                  <BoxShadowTemplate key={index} className="flex p-6 h-64 rounded-xl items-center mx-3">
                    <div className="flex flex-row items-center">
                      <LayoutSpacerVerticalTemplate size="small">
                        <div className='flex items-center justify-between'>
                          <div className="text-sm md:text-md lg:text-md text-left font-bold text-[#000000]">{item.title}</div>
                          <div className='sm:hidden'>
                            <InfoImageHandlerTemplate id={item.id} className="h-20" />
                          </div>
                        </div>
                        <div className="text-sm md:text-md lg:text-md text-left">{item.description}</div>
                      </LayoutSpacerVerticalTemplate>

                      <div className='hidden sm:block my-auto'>
                        <InfoImageHandlerTemplate id={item.id} className="h-32" />
                      </div>
                    </div>
                  </BoxShadowTemplate>
                )
              }
            </CarouselArrow>
          </div>
        </LayoutSpacerVerticalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingInfo;
