import Select from 'react-select';
import { BlockTitleTemplate } from '../../templates/Block/Title';
import { LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { useTranslation } from 'react-i18next';

function CategoryForm(props) {
  const handleChange = (e) => {
    props.setEntity({...props.entity, selectedCategory: e.value})
  }
  const { t } = useTranslation("translation", {keyPrefix: "calculator"})

  return (
    <LayoutSpacerVerticalTemplate size="default" className="w-full sm:w-96 mb-6">
      <BlockTitleTemplate title={t("category.title")} size="default" />
      <Select
        placeholder={t("category.placeholder")}
        onChange={handleChange}
        options={props.categories.map(item => ({value: item.category, label: t(`categoryName.${item.category}`).toUpperCase()}))}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            padding: "8px 16px",
          }),
        }}
      />
    </LayoutSpacerVerticalTemplate>
  );
};

export default CategoryForm;
