import { Link, useParams } from 'react-router-dom';
import { LayoutSpacerVerticalTemplate } from '../LayoutSpacer';
import { ButtonTemplate } from '../Button';
import { LayoutSpacerHorizontalTemplate } from '../LayoutSpacer';
import { BlockTitleTemplate } from '../Block/Title';
import { ImageHandlerTemplate } from '../ImageHandler';
import { useTranslation } from 'react-i18next';

function CategoryProductsTemplate(props) {
  const { t, i18n: {language} } = useTranslation("translation", {keyPrefix: "products"});
  const { locale } = useParams();
  const code = locale ? locale + "/" : "";

  return (
    <div className="grid xl:grid-cols-2 gap-6">
      {props.data.map((item) => (
        <div className="flex bg-[#F5F5F5] rounded-xl relative overflow-clip" key={item.service}>
          <div className="p-6 w-full">
            <LayoutSpacerVerticalTemplate size="small" className="z-10 relative h-full">
              <LayoutSpacerHorizontalTemplate size="small" className="flex items-center justify-between">
                <BlockTitleTemplate title={item.title[language]} size="default" className="text-[#1B1B1B] font-normal" />
                <ImageHandlerTemplate id={item.service} className="ml-auto md:hidden my-auto z-10 max-h-24 -mr-6" />
              </LayoutSpacerHorizontalTemplate>
              <Link to={`/${code}product/${item.service}`} className="mt-auto w-fit self-center md:self-start">
                <ButtonTemplate title={t("button")} size="default" />
              </Link>
            </LayoutSpacerVerticalTemplate>

            <div className="z-auto absolute w-[900px] sm:w-[950px] md:w-[1650px] xl:w-[950px] h-[600px] md:h-[850px] rounded-full border-[#DFE3E5] opacity-40 bg-black/0 border-[140px] md:border-[220px] xl:border-[150px] top-[calc(-150px)] md:top-[calc(-220px)] left-[calc(50%-1150px/2)] md:left-[calc(50%-2050px/2)] xl:left-[calc(50%-1150px/2)]">
            </div>
          </div>
          <ImageHandlerTemplate id={item.service} className="hidden md:flex z-10 h-72 ml-auto" />
        </div>
      ))}
    </div>
  );
}

export default CategoryProductsTemplate;
