import { ButtonTemplate } from "../../templates/Button";
import { BlockTitleTemplate } from "../../templates/Block/Title";

function FormComponent() {
  return (
    <div className="flex flex-col gap-y-12">
      <div className="flex flex-row gap-x-6">
        <div className="flex flex-col flex-1 gap-y-3">
          <BlockTitleTemplate title="Mazmuny ýazyň" size="default" className="text-[#000000] font-normal" />
          <input type="text" placeholder="Mazmuny" className="w-full p-3 border-2 rounded-xl" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-x-6">
        <div className="flex flex-col flex-1 gap-y-3">
          <BlockTitleTemplate title="Adyňyz" size="default" className="text-[#000000] font-normal" />
          <input type="text" placeholder="Adyňyzy ýazyň" className="w-full p-3 border-2 rounded-xl" />
        </div>
        <div className="flex flex-col flex-1 gap-y-3">
          <BlockTitleTemplate title="Telefon belgiňiz" size="default" className="text-[#000000] font-normal" />
          <input type="tel" placeholder="+993" className="w-full p-3 border-2 rounded-xl" />
        </div>
        <div className="flex flex-col flex-1 gap-y-3">
          <BlockTitleTemplate title="E pocta" size="default" className="text-[#000000] font-normal" />
          <input type="email" placeholder="email adresiňiz" className="w-full p-3 border-2 rounded-xl" />
        </div>
      </div>
      <ButtonTemplate title="Ugratmak" size="default"/>
    </div>
  );
}

export default FormComponent;
