import SidebarTemplate from '../../templates/Sidebar';
import { FaBars } from 'react-icons/fa';
import { NavigationItems } from './NavigationItems';
import { LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { Info } from '../HeaderInfo/Info';

const SidebarComponent = () => {
  return (
    <SidebarTemplate
      IconComponent={<FaBars className="text-white text-xl my-3"/>}
      position="left"
      SidebarBodyComponent={
        <LayoutSpacerVerticalTemplate size="small" className="h-[100vh] w-fit mx-6">
          <NavigationItems type="nav" />
          <div className='flex flex-col gap-3 items-center my-auto'>
            <Info/>
          </div>
        </LayoutSpacerVerticalTemplate>
      }
    />
  );
};

export default SidebarComponent;
