import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Listbox } from '@headlessui/react';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { FaGlobeAmericas } from 'react-icons/fa'

const localeOptions = [
  { flag: 'tm', language: 'Turkmen', locale: '' },
  { flag: 'gb', language: 'English', locale: 'en' },
  { flag: 'ru', language: 'Russian', locale: 'ru' },
]

const LanguagesTemplate = () => {
  const navigate = useNavigate();
  const { locale } = useParams();
  const [selected, setSelected] = useState(localeOptions.find((item) => item.locale === locale));

  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative">
        <Listbox.Button className="py-2 flex items-center gap-3">
          <FaGlobeAmericas size={20} className="text-white md:text-gray-700" />
          <span className="uppercase text-white md:text-black">{selected?.locale || "tm"}</span>
        </Listbox.Button>

        <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
          {localeOptions.map((option) => (
            <Listbox.Option
              key={option.flag}
              className={({ active }) => active ? "cursor-default select-none relative text-sm" : "text-gray-900 cursor-default select-none relative text-sm"}
              value={option}
            >
              {({ active }) => (
                <div onClick={() => navigate(`/${option.locale}`)} className="flex items-center gap-x-1 hover:cursor-pointer">
                  <span className={`fi fi-${option.flag} ml-4`}></span>
                  <p className={active ? "text-indigo-500 my-4 mr-4" : "text-gray-500 my-4 mr-4"}>{option.language}</p>
                </div>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default LanguagesTemplate;