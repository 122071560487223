import { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import cx from 'classnames'

export const CarouselCustemIndicatorTemplate = (props) => {
  const [currentIndex, setSlide] = useState(0);

  return (
    <>
      <Carousel
        selectedItem={currentIndex}
        showIndicators={false}
        showStatus={false}
        showArrows={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={2000}
        onChange={(index) => setSlide(index)}
      >
        {props.children}
      </Carousel>
      <div className="flex items-center justify-center gap-x-1">
        {
          Array.from(Array(props.length).keys()).map((item) =>
            <div
              key={item}
              className={cx(
                "bg-gray-200 w-3 h-3 rounded-full hover:cursor-pointer",
                {
                  "bg-green-500 w-6": item === currentIndex,
                }
            )}></div>
          )
        }
      </div>
    </>
  );
};
