import logoPrimary from '../../assets/logo-primary.png';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { useTranslation } from 'react-i18next';
import { NavigationItems } from '../HeaderNavigation/NavigationItems';

function Header() {
  const { t } = useTranslation("translation", {keyPrefix: "main.footer"});
  return (
    <div className="p-3 bg-[#F4F4F4] mt-auto">
      <LayoutContainerHorizontalTemplate>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 py-12">
          <div className='border-b-2 md:border-b-0'>
            <img className="h-12 md:h-16 mx-auto mb-6" src={logoPrimary} alt="logo primary" />
          </div>
          <div className="pt-6 md:pt-0 grid grid-cols-3 md:grid-cols-2 gap-y-3 md:gap-x-6 md:gap-x-12 h-fit w-fit mx-auto">
            <NavigationItems type="footer" />
          </div>
          <div className="mt-6 pt-6 lg:mt-0 lg:pt-0 border-t-2 md:col-span-2 lg:col-span-1 lg:border-t-0 flex flex-col gap-y-3 items-center lg:items-end text-center lg:text-end">
            <div className="text-sm md:text-base font-bold">info@ah-insurance.com.tm</div>
            <div className="text-sm md:text-base font-bold">{t("address")}</div>
            <div className="text-sm md:text-base font-bold">Tel: 957527; 957528; 957529; 957532;</div>
            <div className="text-sm md:text-base font-bold">Fax: 957530</div>
          </div>
        </div>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default Header;
