import call from '../../assets/call.png'
import envelop from '../../assets/envelop.png'
// import speech from '../../assets/speech.png'
import { LayoutSpacerHorizontalTemplate } from '../../templates/LayoutSpacer';

export const Info = () => {
  return (
    <>
      <LayoutSpacerHorizontalTemplate size="small" className="flex items-center">
        <img src={envelop} className='h-4' alt="envelop" />
        <p>info@ah-insurance.com</p>
      </LayoutSpacerHorizontalTemplate>
      <LayoutSpacerHorizontalTemplate size="small" className="flex items-center">
        <img src={call} className='h-4' alt="call" />
        <p>+99312957529</p>
      </LayoutSpacerHorizontalTemplate>
      {/* <LayoutSpacerHorizontalTemplate size="small" className="flex items-center">
        <img src={speech} className='h-4' alt="speech" />
        <p>Köp soralýän soraglar</p>
      </LayoutSpacerHorizontalTemplate> */}
    </>
  );
};
