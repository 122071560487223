import cx from 'classnames';

export const BoxShadowTemplate = (props) => {
  const container = cx(
    props.className,
    "shadow-[0_4px_13px_rgba(0,0,0,0.3)]"
  );

  return (
    <div className='py-6'>
      <div className={container}>
        {props.children}
      </div>
    </div>
  )
};
