import { BlockTitleTemplate } from '../../templates/Block/Title';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { useTranslation } from 'react-i18next';

function AboutComponent() {
  const { t } = useTranslation("translation", {keyPrefix: "about"});
  return (
    <div className="p-3 py-12">
      <LayoutContainerHorizontalTemplate>
        <LayoutPaddingHorizontalTemplate size="xlarge">
          <BlockTitleTemplate title={t("title")} size="large" className="text-[#343338] text-center mb-6" />
          <LayoutSpacerVerticalTemplate size="default">
            {t("descriptions", {returnObjects: true}).map((item, index) => {
              if (typeof item === "string") {
                return <p key={index}>{item}</p>
              }
              return (
                <LayoutSpacerVerticalTemplate size="small">
                  <BlockTitleTemplate title={item.title} size="xsmall"/>
                  <ul className="flex flex-col gap-3">
                    {item.descriptions.map((item, index) =>
                      <li className="before:content-[''] before:inline-block before:w-3 before:md:w-9 before:h-1 before:bg-[#40A054] before:rounded-lg before:mr-3 before:mb-[3px]" key={index}>{item}</li>
                    )}
                  </ul>
                </LayoutSpacerVerticalTemplate>
              )
            })}
          </LayoutSpacerVerticalTemplate>
        </LayoutPaddingHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default AboutComponent;
