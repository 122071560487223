import cx from "classnames";
import { FaCheck } from "react-icons/fa"

export const CheckboxTemplate = (props) => {
  const checkContainer = cx(
    "absolute w-5 h-5 border-2 rounded-md flex items-center justify-center",
    {
      "border-[#40A054]": props.checked
    }
  )

  return (
    <div className="relative flex">
      <div className={checkContainer}>
        {props.checked && <FaCheck size={12}/>}
      </div>
      <input type="checkbox" className="opacity-0 hover:cursor-pointer bottom top-0 w-5 h-5" {...props} />
    </div>
  )
}