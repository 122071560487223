import { BlockTitleTemplate } from '../../templates/Block/Title';
import DisclosureTemplate from '../../templates/Disclosure';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { LayoutSpacerHorizontalTemplate, LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { useTranslation } from 'react-i18next';

function LandingQuestions() {
  const { t } = useTranslation("translation", {keyPrefix: "questions"});
  return (
    <div className="p-3 py-12">
      <LayoutContainerHorizontalTemplate>
        <LayoutPaddingHorizontalTemplate size="xlarge">
          <LayoutSpacerHorizontalTemplate size="default" className="flex-col divide-y lg:divide-x lg:divide-y-0 lg:flex-row">
            <LayoutSpacerVerticalTemplate size="default" className="flex-1 mb-6">
              <BlockTitleTemplate title={t("title")} size="large" className="text-[#343338]" />
              <div className='text-sm md:text-base'>{t("description")}</div>
            </LayoutSpacerVerticalTemplate>

            <LayoutSpacerVerticalTemplate size="default" className="flex-1 lg:pl-6">
              <BlockTitleTemplate title={t("title2")} size="large" className="text-[#343338] mt-12 lg:mt-0" />
              <div className="grid grid-cols-1 divide-y">
                {t("questions", {returnObjects: true}).slice(0, 5).map((item, index) =>
                  <DisclosureTemplate
                    key={index}
                    DisclosureTitleComponent={
                      <div className="text-sm md:text-base py-6 px-2 lg:px-6">{item.question}</div>
                    }
                    DisclosureBodyComponent={
                      <div className="text-sm md:text-base px-4 lg:px-12 pb-6 -mt-3">
                        {item.answers.map((item, index) =>
                          <li key={index}>{item}</li>
                        )}
                      </div>
                    }
                  />
                )}
              </div>
            </LayoutSpacerVerticalTemplate>
          </LayoutSpacerHorizontalTemplate>
        </LayoutPaddingHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingQuestions;
