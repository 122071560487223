import { useParams } from 'react-router-dom';
import { BlockTitleTemplate } from '../../templates/Block/Title';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { MarkdownTemplate } from '../../templates/Markdown';
import data from '../../../data.json';
import { useTranslation } from 'react-i18next';

function ProductComponent() {
  const { productId } = useParams();
  const item = data.filter((item) => item.id === productId)[0];
  const { i18n: {language} } = useTranslation();
  console.log(language)

  return (
    <div className="p-3 py-12 bg-[#F4F4F4]">
      <LayoutContainerHorizontalTemplate>
        <LayoutSpacerVerticalTemplate size="large">
          <LayoutSpacerVerticalTemplate size="default">
            {
              item.description && (
                <LayoutSpacerVerticalTemplate size="small">
                  <MarkdownTemplate>
                    {item.description[language]}
                  </MarkdownTemplate>
                </LayoutSpacerVerticalTemplate>
              )
            }

            {language === "tm" && 
              <LayoutSpacerVerticalTemplate size="small">
                <BlockTitleTemplate size="default" title="Resminamalar:"/>
                <ul className="flex flex-col gap-3">
                  {item.documents.map((item, index) => <li className="before:content-[''] before:inline-block before:w-3 before:md:w-9 before:h-1 before:bg-[#40A054] before:rounded-lg before:mr-3 before:mb-[3px]" key={index}>{item}</li>)}
                </ul>
              </LayoutSpacerVerticalTemplate>
            }
          </LayoutSpacerVerticalTemplate>
        </LayoutSpacerVerticalTemplate>

      </LayoutContainerHorizontalTemplate>
    </div>
  );
};

export default ProductComponent;
