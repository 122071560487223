import dayjs from "dayjs";

export const calculate = (data, coefficient, monthly) => {
  let dateCoefficient = 0;
  const initialDate = dayjs(data.initialDate);
  const finalDate = dayjs(data.finalDate);
  const amount = Number(data.amount);

  if (monthly) {
    const diffMonth = finalDate.diff(initialDate, "month");
    const fewMonth = diffMonth % 12;

    if (fewMonth === 1) {
      dateCoefficient = 0.20;
    } else if (fewMonth === 2 ) {
      dateCoefficient = 0.30;
    } else if (fewMonth === 3 ) {
      dateCoefficient = 0.40;
    } else if (fewMonth === 4 ) {
      dateCoefficient = 0.50;
    } else if (fewMonth === 5 ) {
      dateCoefficient = 0.60;
    } else if (fewMonth === 6 ) {
      dateCoefficient = 0.70;
    } else if (fewMonth === 7 ) {
      dateCoefficient = 0.75;
    } else if (fewMonth === 8 ) {
      dateCoefficient = 0.80;
    } else if (fewMonth === 9 ) {
      dateCoefficient = 0.85;
    } else if (fewMonth === 10 ) {
      dateCoefficient = 0.90;
    } else if (fewMonth === 11 ) {
      dateCoefficient = 0.95;
    }

    const fullYearCoefficient = (diffMonth - fewMonth) / 12;
    dateCoefficient = dateCoefficient + fullYearCoefficient;

  } else {
    const diffDate = finalDate.diff(initialDate, "day");
    dateCoefficient = diffDate / 365;

    if (coefficient.firstDay) {
      if (diffDate === 1) {
        return ((coefficient.firstDay * amount / 100 ) * dateCoefficient);
      }
      return (((coefficient.firstDay * amount / 100 ) * (1 / 365)) + (coefficient.other * amount / 100 ) * ((diffDate - 1) / 365));
    }

    if (coefficient.limitedMoney) {
      if (amount <= 250000) {
        return ((coefficient.limitedMoney * amount / 100 ) * dateCoefficient);
      }
      return ((coefficient.other * amount / 100 ) * dateCoefficient);
    }
  }

  const res = ((coefficient * amount / 100 ) * dateCoefficient);
  return res;
};

export const getCoefficient = (answers, selectedItems) => {
  let coefficient = 0;
  answers.forEach(ans => {
    if (selectedItems.includes(ans.title)) {
      coefficient = coefficient + ans.coefficient * 1000
    }
  })

  // special for s7, please be sure other services don't use exact these numbers
  if (coefficient === 85) {
    return 0.09
  }
  if (coefficient === 235) {
    return 0.24
  }
  if (coefficient === 285) {
    return 0.29
  }

  return coefficient / 1000;
}
