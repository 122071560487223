import cx from 'classnames';
import { FaTimes } from 'react-icons/fa';

const SidebarBodyTemplate = (props) => {
  const container = cx(
    'fixed inset-y-0 bg-[#F4F4F4] border-l-2 z-20',
    {
      'right-0': props.position === 'right',
      'left-0': props.position === 'left',
    },
  );

  return (
    <div className={container}>
      <div className="flex justify-end border-b-2">
        <div onClick={() => props.setFilterOpen(false)} className="p-2 border-l-2 hover:cursor-pointer">
          <FaTimes size={24} color="black"/>
        </div>
      </div>
  
      <div className="p-8">
        {props.SidebarBodyComponent}
      </div>
    </div>
  );
}

export default SidebarBodyTemplate;
