import serData from  '../../../serData.json';
import CategoryForm from './CategoryForm';
import ServiceForm from './ServiceForm';

function FormComponent(props) {
  const categories = serData.filter((item)=> item.entityType.includes(props.entity.entityType));

  return (
    <div>
      <CategoryForm categories={categories} entity={props.entity} setEntity={props.setEntity}/>
      {props.entity.selectedCategory &&
        <ServiceForm data = {categories.filter(item => item.category === props.entity.selectedCategory)} key={props.entity.selectedCategory} />
      }
    </div>
  );
}

export default FormComponent;
