import cx from 'classnames';
import car from '../../assets/car.png';
import suitcase from '../../assets/suitcase.png';
import sofa from '../../assets/sofa.png';
import luggage from '../../assets/luggage.png';
import box from '../../assets/box.png';
import trunk from '../../assets/trunk.png';
import backpack from '../../assets/backpack.png';
import coin from '../../assets/coin.png';
import construction from '../../assets/construction.png';
import danger from '../../assets/danger.png';
import derrick from '../../assets/derrick.png';
import wheat from '../../assets/wheat.png';
import dog from '../../assets/dog.png';
import helmet from '../../assets/helmet.png';
import horse from '../../assets/horse.png';
import house from '../../assets/house.png';
import jet from '../../assets/jet.png';
import keychain from '../../assets/keychain.png';
import motor from '../../assets/motor.png';
import pass from '../../assets/pass.png';
import phone from '../../assets/phone.png';
import plane from '../../assets/plane.png';
import riton from '../../assets/riton.png';
import sput from '../../assets/sput.png';
import tank from '../../assets/tank.png';
import tree from '../../assets/tree.png';
import umbrella from '../../assets/umbrella.png';
import whistle from '../../assets/whistle.png';


export const ImageHandlerTemplate = (props) => {
  let src = "";
  if ( props.id ==="s1" ) src = sofa;
  if ( props.id ==="s2" ) src = car;
  if ( props.id ==="s3" ) src = suitcase;
  if ( props.id ==="s4" ) src = umbrella;
  if ( props.id ==="s5" ) src = house;
  if ( props.id ==="s6" ) src = helmet;
  if ( props.id ==="s7" ) src = construction;
  if ( props.id ==="s8" ) src = "";
  if ( props.id ==="s9" ) src = box;
  if ( props.id ==="s10" ) src = keychain;
  if ( props.id ==="s11" ) src = jet;
  if ( props.id ==="s12" ) src = trunk;
  if ( props.id ==="s13" ) src = derrick;
  if ( props.id ==="s14" ) src = plane;
  if ( props.id ==="s15" ) src = luggage;
  if ( props.id ==="s16" ) src = coin;
  if ( props.id ==="s17" ) src = "";
  if ( props.id ==="s18" ) src = "";
  if ( props.id ==="s19" ) src = "";
  if ( props.id ==="s20" ) src = phone;
  if ( props.id ==="s21" ) src = tank;
  if ( props.id ==="s22" ) src = "";
  if ( props.id ==="s23" ) src = backpack;
  if ( props.id ==="s24" ) src = dog;
  if ( props.id ==="s25" ) src = pass;
  if ( props.id ==="s26" ) src = motor;
  if ( props.id ==="s27" ) src = horse;
  if ( props.id ==="s28" ) src = tree;
  if ( props.id ==="s29" ) src = riton;
  if ( props.id ==="s30" ) src = whistle;
  if ( props.id ==="s31" ) src = wheat;
  if ( props.id ==="s32" ) src = sput;
  if ( props.id ==="s33" ) src = danger;

  const container = cx(
    "object-contain", props.className,
  );

  return (
    <img className={container} src={src} alt="product" style={{width: "fit-content"}}/>
  );
};
