import logoPrimary from '../../assets/logo-primary.png';
import logoSecondary from '../../assets/logo-secondary.png';
import { BlockTitleTemplate } from '../../templates/Block/Title';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutSpacerHorizontalTemplate } from '../../templates/LayoutSpacer';

function Header() {
  return (
    <div className="p-3 mt-9 md:mt-0 bg-[#F4F4F4] md:bg-white">
      <LayoutContainerHorizontalTemplate>
        <div className="flex justify-between gap-3">
          <img className="h-9 md:h-16" src={logoPrimary} alt="logo primary" />
          <LayoutSpacerHorizontalTemplate size="small" className="flex items-center justify-center">
            <BlockTitleTemplate title="ARKADAG SERDARLY BAGTYÝAR ÝAŞLAR ÝYLY" size="small" className="text-right font-normal text-[#009100] w-40 md:w-64"/>
            <img className="h-9 md:h-16" src={logoSecondary} alt="logo secondary" />
          </LayoutSpacerHorizontalTemplate>
        </div>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default Header;
