import cx from 'classnames';
import clock from '../../assets/clock.png';
import ring from '../../assets/ring.png';
import sign from '../../assets/sign.png';
import documents from '../../assets/documents.png';

export const InfoImageHandlerTemplate = (props) => {
  let src = "";
  if ( props.id ==="1" ) src = clock;
  if ( props.id ==="2" ) src = ring;
  if ( props.id ==="3" ) src = documents;
  if ( props.id ==="4" ) src = sign;

  const container = cx(
    "object-contain", props.className,
  );

  return (
    <img className={container} src={src} alt="landing-info" style={{maxWidth: "160px", width: "fit-content"}} />
  );
};
