import { useParams, Link } from 'react-router-dom';
import { ButtonTemplate } from '../../templates/Button';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import calculator from '../../assets/calculator.png'
import { LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { LayoutPaddingHorizontalTemplate, LayoutPaddingVerticalTemplate } from '../../templates/LayoutPadding';
import { useTranslation } from 'react-i18next';
import { SectionTitleTemplate } from '../../templates/Section/Title';

function LandingCalculations() {
  const { t } = useTranslation("translation", {keyPrefix: "main.calculation"});
  const { locale } = useParams();
  const code = locale ? locale + "/" : "";
  return (
    <div className="p-3 py-12 bg-[#F4F4F4]">
      <LayoutContainerHorizontalTemplate>
        <div className='px-4 md:px-24 lg:px-0'>
          <div className="grid grid-r-2 lg:grid-cols-2 bg-[#CDD5E0] rounded-xl overflow-clip">
            <img className="my-auto object-contain lg:order-last" src={calculator} alt="calculator" />
            <LayoutPaddingHorizontalTemplate size="xlarge">
              <LayoutPaddingVerticalTemplate size="large" className="h-full">
                <LayoutSpacerVerticalTemplate size="default" className="h-full items-center lg:items-start">
                  <SectionTitleTemplate
                    title={t("title")}
                    className="text=[#252525]"
                  />
                  <div className="text-base text-center lg:text-left text-black">{t("description")}</div>
                  <Link to={`/${code}calculator`} className='mt-auto'>
                    <ButtonTemplate title={t("button")} size="default" />
                  </Link>
                </LayoutSpacerVerticalTemplate>
              </LayoutPaddingVerticalTemplate>
            </LayoutPaddingHorizontalTemplate>  
          </div>
        </div>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingCalculations; 
