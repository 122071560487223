import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronCircleRight, FaChevronCircleLeft } from "react-icons/fa"

export const CarouselArrow = (props) => {
  const settings = {
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <div className='lg:-m-3'>
        <Slider {...settings}>
          {props.children}
        </Slider>
      </div>
  );
};

function SampleNextArrow(props) {
  return (
    <div className='absolute top-1/2 -right-8 md:-right-20 lg:-right-24 cursor-pointer'>
      <FaChevronCircleRight size={34} color="#BBBBBB" onClick={props.onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  return (
    <div className='absolute top-1/2 -left-8 md:-left-20 lg:-left-24 cursor-pointer'>
      <FaChevronCircleLeft size={34} color="#BBBBBB" onClick={props.onClick} />
    </div>
  );
}
