import { useParams } from 'react-router-dom';
import { BlockTitleTemplate } from '../../templates/Block/Title';
import { ButtonTemplate } from '../../templates/Button';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingVerticalTemplate } from '../../templates/LayoutPadding';
import { LayoutSpacerVerticalTemplate, LayoutSpacerHorizontalTemplate } from '../../templates/LayoutSpacer';
import data from '../../../data.json';
import { ImageHandlerTemplate } from '../../templates/ImageHandler';
import { useTranslation } from 'react-i18next';

function ProductBanner() {
  const { t, i18n: {language} } = useTranslation("translation", {keyPrefix: "product.banner"});
  const { productId } = useParams();
  const item = data.filter((item) => item.id === productId)[0];

  return (
    <div className="p-3 py-12 bg-[#F4F4F4]">
      <LayoutContainerHorizontalTemplate>
        <div className="flex gap-3 bg-[#e8ebdf] rounded-xl overflow-hidden items-center">
          <LayoutPaddingVerticalTemplate size="small">
            <div className="pl-3 sm:pl-6 lg:pl-28">
              <LayoutSpacerHorizontalTemplate size="small" className="flex items-center">
                <div className="flex flex-col py-3 lg:py-6">
                  <LayoutSpacerVerticalTemplate size="default">
                    <LayoutSpacerHorizontalTemplate size="small" className="flex items-center justify-between"> 
                      <BlockTitleTemplate
                        title={item.title[language]}
                        size="large"
                        className="text=[#252525]"
                      />
                      <ImageHandlerTemplate id={item.id} className="md:hidden w-16 my-auto z-10 max-h-24" />
                    </LayoutSpacerHorizontalTemplate>
                    <div className="text-sm md:text-base text-left text-[#8C8C8C]">{item.subTitle[language]}</div>
                    <div className="mx-auto md:ml-0">
                      <ButtonTemplate title={t("button")} size="default" />
                    </div>
                  </LayoutSpacerVerticalTemplate>
                </div>

                {/* <ImageHandlerTemplate id={item.id} className="hidden md:flex ml-auto" /> */}
              </LayoutSpacerHorizontalTemplate>
            </div>
          </LayoutPaddingVerticalTemplate>
          <ImageHandlerTemplate id={item.id} className="hidden md:flex z-10 h-96 ml-auto" />
        </div>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default ProductBanner;
