import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { SectionTitleTemplate } from '../../templates/Section/Title';
import serData from '../../../serData.json';
import { BlockTitleTemplate } from '../../templates/Block/Title';
import { useTranslation } from 'react-i18next';
import CategoryProductsTemplate from '../../templates/CategoryProducts';

function getCategories() {
  let categories = [];
  serData.forEach((element) => {
    if (!categories.includes(element.category)) {
      categories.push(element.category)
    }
  })
  return categories.sort();
}

function getItems(category) {
  let items = [];
  serData.forEach((element) => {
    if (element.category === category) {
      items = items.concat(element.items)
    }
  })
  items = items.filter((item, index) => items.findIndex((x) => x.service === item.service) === index);
  return items;
}

function LandingInfo() {
  const { t } = useTranslation("translation");
  const categories = getCategories();

  return (
    <div className="p-3 py-12">
      <LayoutContainerHorizontalTemplate>
        <SectionTitleTemplate title={t("products.sectionTitle")} className="self-start mb-6" />
        {categories.map((category, index) =>
          <div>
            <BlockTitleTemplate size="large" title={t(`calculator.categoryName.${category}`).toUpperCase()} />
            <div className='mt-6 mb-20'>
              <CategoryProductsTemplate data={getItems(category)} />
            </div>
          </div>
        )}
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingInfo;
