import cx from 'classnames';

export const SectionTitleTemplate = (props) => {
  const container = cx(
    'text-2xl md:text-4xl font-bold text-center text-[#212529] mx-auto md:mx-0',
    props.className,
  );

  return (
    <p className={container}>
      <span>{props.title}</span>
    </p>
  );
};
