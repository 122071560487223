import { useState } from 'react';
import FormComponent from './Form';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { BoxShadowTemplate } from '../../templates/BoxShadow';
import { SectionTitleTemplate } from '../../templates/Section/Title';
import EntityForm from './EntityForm';
import { useTranslation } from 'react-i18next';

function LandingProductsCalculations() {
  const [entity, setEntity] = useState({
    entityType: "Fiziki Sahslar",
    selectedCategory: ""
  });
  const { t } = useTranslation("translation", {keyPrefix: "calculator"})

  return (
    <div className="p-3 py-12">
      <LayoutContainerHorizontalTemplate>
        <LayoutPaddingHorizontalTemplate size="xlarge">
          <LayoutSpacerVerticalTemplate size="large">
            <SectionTitleTemplate title={t("sectionTitle")} />
            <EntityForm setEntity={setEntity} entity={entity} />

            <div className='min-h-[25rem]'>
              <BoxShadowTemplate className="rounded-xl">
                <div className="border-2 p-6 rounded-xl bg-white">
                  <FormComponent entity={entity} setEntity={setEntity} key={entity.entityType} />
                </div>
              </BoxShadowTemplate>
            </div>
          </LayoutSpacerVerticalTemplate>
        </LayoutPaddingHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingProductsCalculations; 
