import FormComponent from './Form';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { BoxShadowTemplate } from '../../templates/BoxShadow';
import { SectionTitleTemplate } from '../../templates/Section/Title';

function LandingContact() {
  return (
    <div className="p-3 py-12">
      <LayoutContainerHorizontalTemplate>
        <LayoutPaddingHorizontalTemplate size="xlarge">
          <SectionTitleTemplate title="Habarlaşmak" className="self-start mb-3" />

          <BoxShadowTemplate className="rounded-xl">
            <div className="border-2 p-6 rounded-xl bg-white">
              <FormComponent />
            </div>
          </BoxShadowTemplate>
        </LayoutPaddingHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingContact; 
