import cx from 'classnames';

export const LayoutPaddingVerticalTemplate = (props) => {
  const container = cx(
    'flex flex-col',
    props.className,
    {
      'py-3': props.size === 'small',
      'py-6': props.size === 'default',
      'py-12': props.size === 'large',
    }
  );

  return (
    <div className={container}>
      {props.children}
    </div>
  )
};

export const LayoutPaddingHorizontalTemplate = (props) => {
  const container = cx(
    'flex flex-col',
    props.className,
    {
      'px-3': props.size === 'small',
      'px-6': props.size === 'default',
      'px-12': props.size === 'large',
      'px-3 sm:px-6 lg:px-28': props.size === 'xlarge',
    }
  );

  return (
    <div className={container}>
      {props.children}
    </div>
  )
};
