import { BlockTitleTemplate } from '../../templates/Block/Title';
import DisclosureTemplate from '../../templates/Disclosure';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { useTranslation } from 'react-i18next';

function QuestionsComponent() {
  const { t } = useTranslation("translation", {keyPrefix: "questions"});
  return (
    <div className="p-3 py-12">
      <LayoutContainerHorizontalTemplate>
        <LayoutPaddingHorizontalTemplate size="xlarge">
          <BlockTitleTemplate title={t("title2")} size="large" className="text-[#343338] mb-3" />
          <div className="grid grid-cols-1 divide-y">
            {t("questions", {returnObjects: true}).map((item, index) =>
              <DisclosureTemplate
                key={index}
                DisclosureTitleComponent={
                  <div className="p-6">{item.question}</div>
                }
                DisclosureBodyComponent={
                  <div className="px-12 pb-6 -mt-3">
                    {item.answers.map((item, index) =>
                      <li key={index}>{item}</li>
                    )}
                  </div>
                }
              />
            )}
          </div>
        </LayoutPaddingHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default QuestionsComponent;
