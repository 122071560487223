import { Link, useHref, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

export const NavigationItems = (props) => {
  const href = useHref();
  const { t } = useTranslation("translation", {keyPrefix: "main.header.navigation"});
  const { locale } = useParams();
  const code = locale ? locale + "/" : "";
  
  const linkClass = cx(
    "relative",
    {
      "text-white py-6": props.type === "header",
      "text-black py-3": props.type === "nav",
      "text-black hover:text-[#40A054]": props.type === "footer"
    }
  );
  const activeClass = cx(
    "absolute inset-x-0 bottom-0 h-1 rounded-t-full",
    {
      "bg-white": props.type === "header",
      "bg-[#40A054]": props.type === "nav",
      "h-0": props.type === "footer"
    }
  );
  
  return (
    <>
      <Link to={`/${code.slice(0, -1)}`} className={linkClass}>
        <p className='text-sm md:text-base text-center'>{t("home")}</p>
        {href === `/${code.slice(0, -1)}` && <div className={activeClass}></div>}
      </Link>
      <Link to={`/${code}products`} className={linkClass}>
        <p className='text-sm md:text-base text-center'>{t("service")}</p>
        {(href === `/${code}products` || href.includes("/product/")) && <div className={activeClass}></div>}
      </Link>
      <Link to={`/${code}calculator`} className={linkClass}>
        <p className='text-sm md:text-base text-center'>{t("calculator")}</p>
        {href === `/${code}calculator` && <div className={activeClass}></div>}
      </Link>
      <Link to={`/${code}questions`} className={linkClass}>
        <p className='text-sm md:text-base text-center'>{t("questions")}</p>
        {href === `/${code}questions` && <div className={activeClass}></div>}
      </Link>
      <Link to={`/${code}about`} className={linkClass}>
        <p className='text-sm md:text-base text-center'>{t("about")}</p>
        {href === `/${code}about` && <div className={activeClass}></div>}
      </Link>
      <Link to={`/${code}contact`} className={linkClass}>
        <p className='text-sm md:text-base text-center'>{t("contact")}</p>
        {href === `/${code}contact` && <div className={activeClass}></div>}
      </Link>
    </>
  );
};