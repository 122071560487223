import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { calculate } from '../../services/calculate';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LayoutPaddingVerticalTemplate } from '../LayoutPadding';
import { LayoutSpacerHorizontalTemplate, LayoutSpacerVerticalTemplate } from '../LayoutSpacer';
import { BlockTitleTemplate } from '../Block/Title';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const CalculateFormTemplate = (props) => {
  const { t } = useTranslation("translation", {keyPrefix: "calculator.form"});
  const [result, setResult] = useState(0);
  
  const schema = yup.object().shape({
    amount: yup.number().typeError("san girizmli").min(0, "noldan uly san bolmaly"),
    initialDate: yup.date().typeError("bashlayan wagtyny giriz"),
    finalDate: yup.date().typeError("gutaryan wagtyny giriz").test("test-final",
      function(value) {
        const { initialDate } = this.parent;
        const { path, createError } = this;
        if (props.monthly) {
          if ((initialDate.getDate() === value.getDate() + 1) || (initialDate.getDate() === 1 &&  value.getDate() === dayjs(value).endOf("month").date())) {
            return true
          } else {
            return createError({message: "Doly 1 ay wagt bolmaly", path});
          }
        } else {
          if (value <= initialDate) {
            return createError({message: "Azyndan 1gun wagt bolmaly", path});
          } else {
            return true;
          }
        }
      }
    )
  });
  
  const { register, handleSubmit, formState: {errors} } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    const res = calculate(data, props.coefficient, props.monthly)
    setResult(Math.round(res * 100) / 100);
  }

  return (
    <>
    <LayoutPaddingVerticalTemplate size="default" className="border-t-2">
      <form className='' onSubmit={handleSubmit(onSubmit)}>
        <LayoutSpacerHorizontalTemplate size="large">
          <LayoutSpacerVerticalTemplate size="small">
            <BlockTitleTemplate title={t('dateTitle')} size="default" className="mt-6"/>

            <LayoutSpacerHorizontalTemplate size="default" className="flex flex-col sm:flex-row">
              <div>
                <input type="date" min={new Date(Date.now() + 86400000).toISOString().split("T")[0]} {...register("initialDate")} className="focus:outline-none border-2 rounded-lg px-3 py-3 md:px-6 md:py-3" />
                <p className='text-red-700'>{errors.initialDate?.message}</p>
              </div>
              <div>
                <input type="date" min={new Date(Date.now() + 86400000).toISOString().split("T")[0]} {...register("finalDate")} className="focus:outline-none border-2 rounded-lg px-3 py-3 md:px-6 md:py-3" />
                <p className='text-red-700'>{errors.finalDate?.message}</p>
              </div>
            </LayoutSpacerHorizontalTemplate>
          </LayoutSpacerVerticalTemplate>

          <LayoutSpacerVerticalTemplate size="small">
            <BlockTitleTemplate title={t("moneyTitle")} size="default" className="mt-6" />
            <div>
              <input type='text' {...register("amount")} className="focus:outline-none border-2 rounded-lg px-6 py-3 text-xl" />
              <span className="pl-3">TMT</span>
              <p className='text-red-700'>{errors.amount?.message}</p>
            </div>
          </LayoutSpacerVerticalTemplate>
        </LayoutSpacerHorizontalTemplate>
        <input type="submit" value={t("button")} className="block rounded-lg bg-blue-500 text-white hover:cursor-pointer px-6 py-3 mt-6" />
        
      </form>
    </LayoutPaddingVerticalTemplate>
    <LayoutPaddingVerticalTemplate size="default" className="border-t-2">
      <LayoutSpacerHorizontalTemplate size="small" className="flex justify-between items-center" >
        <BlockTitleTemplate title={t("totalTitle")} size="small" className="font-normal" />
        {result !== 0 && <div className="text-base md:text-xl text-black font-bold">{t("total")}: {result}  TMT</div>}
      </LayoutSpacerHorizontalTemplate>
    </LayoutPaddingVerticalTemplate>
    </>
  );
};

export default CalculateFormTemplate;
