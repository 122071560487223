import luggage from '../../assets/luggage.png';
import sofa from '../../assets/sofa.png';
import suitcase from '../../assets/suitcase.png';
import car from '../../assets/car.png';
import { useParams, Link } from 'react-router-dom';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { LayoutSpacerHorizontalTemplate, LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { SectionTitleTemplate } from '../../templates/Section/Title';
import { BlockTitleTemplate } from '../../templates/Block/Title';
import { useTranslation } from 'react-i18next';

function LandingInfo() {
  const { t } = useTranslation("translation", {keyPrefix: "main.service"});
  const { locale } = useParams();
  const code = locale ? locale + "/" : "";
  return (
    <div className="p-3 py-12 bg-[#F4F4F4]">
      <LayoutContainerHorizontalTemplate>
        <LayoutPaddingHorizontalTemplate size="xlarge">

          <LayoutSpacerVerticalTemplate size="large">
            <SectionTitleTemplate title={t("title")} className="self-start" />

            <LayoutSpacerHorizontalTemplate size="default" className="grid grid-cols-2">
              <div className="flex flex-1 bg-white py-6 px-3 rounded-xl justify-center relative z-10 border-2">
                <div className="">
                  <img className="h-36 object-contain" src={car} alt="car" />

                  <div className="flex flex-col gap-y-12 items-center">
                    <BlockTitleTemplate title={t("VEHICLE")} size="small" className="text-[#343338]" />
                    <Link to={`/${code}product/s2`} className="hover:font-bold">
                      <div className="text-center text-base">{t("itemButton")}</div>
                    </Link>
                  </div>
                </div>
                <div className="absolute top-0 inset-x-0 bg-[#EEFFF2] h-24 -z-10 rounded-t-xl"></div>
              </div>

              <div className="flex flex-1 bg-white py-6 px-3 rounded-xl justify-center relative z-10 border-2">
                <div className="">
                  <img className="h-36 object-contain" src={suitcase} alt="suitcase" />

                  <div className="flex flex-col gap-y-12 items-center">
                    <BlockTitleTemplate title={t("Health")} size="small" className="text-[#343338]" />
                    <Link to={`/${code}product/s3`} className="hover:font-bold">
                      <div className="text-center text-base">{t("itemButton")}</div>
                    </Link>
                  </div>
                </div>
                <div className="absolute top-0 inset-x-0 bg-[#F2F4EA] h-24 -z-10 rounded-t-xl"></div>
              </div>

              <div className="flex flex-1 bg-white py-6 px-3 rounded-xl justify-center relative z-10 border-2">
                <div className="">
                  <img className="h-36 object-contain" src={sofa} alt="sofa" />

                  <div className="flex flex-col gap-y-12 items-center">
                    <BlockTitleTemplate title={t("PROPERTY")} size="small" className="text-[#343338]" />
                    <Link to={`/${code}product/s1`} className="hover:font-bold">
                      <div className="text-center text-base">{t("itemButton")}</div>
                    </Link>
                  </div>
                </div>
                <div className="absolute top-0 inset-x-0 bg-[#F4F9FF] h-24 -z-10 rounded-t-xl"></div>
              </div>

              <div className="flex flex-1 bg-white py-6 px-3 rounded-xl justify-center relative z-10 border-2">
                <div className="">
                  <img className="h-36 object-contain" src={luggage} alt="luggage" />

                  <div className="flex flex-col gap-y-12 items-center">
                    <BlockTitleTemplate title={t("Tourism")} size="small" className="text-[#343338]" />
                    <Link to={`/${code}product/s15`} className="hover:font-bold">
                      <div className="text-center text-base">{t("itemButton")}</div>
                    </Link>
                  </div>
                </div>
                <div className="absolute top-0 inset-x-0 bg-[#EFEFF6] h-24 -z-10 rounded-t-xl"></div>
              </div>
            </LayoutSpacerHorizontalTemplate>
          </LayoutSpacerVerticalTemplate>
        </LayoutPaddingHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingInfo;
