import { ButtonTemplate } from "../../templates/Button";
import { LayoutPaddingHorizontalTemplate } from "../../templates/LayoutPadding";
import { LayoutSpacerHorizontalTemplate } from "../../templates/LayoutSpacer";
import { useTranslation } from "react-i18next";

function EntityForm(props) {
  const { t } = useTranslation("translation", {keyPrefix: "calculator.entityType"})
  return (
    <LayoutPaddingHorizontalTemplate size="small">
      <LayoutSpacerHorizontalTemplate size="small" className="flex mx-auto w-fit items-center border-2 rounded-xl">
        <div onClick={() => props.setEntity({selectedCategory: "", entityType: "Fiziki Sahslar"})}>
          <ButtonTemplate title={t("individuals")} size="small" disabled={props.entity.entityType !== "Fiziki Sahslar"} />
        </div>
        <div onClick={() => props.setEntity({selectedCategory: "", entityType: "ýuridiki şahslary we hususy telekeçiler"})}>
          <ButtonTemplate title={t("legal")} size="small" disabled={props.entity.entityType === "Fiziki Sahslar"} />
        </div>
      </LayoutSpacerHorizontalTemplate>
    </LayoutPaddingHorizontalTemplate>
  );
}

export default EntityForm;
