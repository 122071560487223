import { useState } from "react";
import calcData from  '../../../calcData.json';
import RecursionFormTemplate from "../../templates/RecursionForm";
import CalculateFormTemplate from "../../templates/CalculateForm";
import { CheckboxTemplate } from "../../templates/Checkbox";
import { LayoutSpacerVerticalTemplate } from "../../templates/LayoutSpacer";
import { LayoutPaddingVerticalTemplate } from "../../templates/LayoutPadding";
import { useTranslation } from 'react-i18next';

function ServiceForm(props) {
  const [service, setService] = useState("");
  const data = calcData.filter((calc) => calc.service === service)[0];
  const { t, i18n: {language} } = useTranslation("translation", {keyPrefix: "calculator"});

  return (
    <div className="border-t-2 mt-9">
      <LayoutPaddingVerticalTemplate size="default">
        <LayoutSpacerVerticalTemplate size="small">
          {props.data.map((item) => item.items.map((x) =>
            <label key={x.service} className="flex items-center gap-6 text-sm md:text-base w-fit">
              <CheckboxTemplate
                  checked={service === x.service}
                  onChange={() => setService(x.service)}
                />
                {x.title[language]}
            </label>
          ))}
        </LayoutSpacerVerticalTemplate>
      </LayoutPaddingVerticalTemplate>
      
      {service === "s19" && <p className="italic">{t("s19")}</p>}
      {data && data.answers &&
        <RecursionFormTemplate data={data} monthly={data.monthly} key={data.service}/>
      }
      {data && data.coefficient &&
        <CalculateFormTemplate coefficient={data.coefficient} />
      }
    </div>
  );
};

export default ServiceForm;
