import { useState } from "react";
import CalculateFormTemplate from "../CalculateForm";
import { CheckboxTemplate } from "../Checkbox";
import { LayoutSpacerVerticalTemplate } from "../LayoutSpacer";
import { LayoutPaddingVerticalTemplate } from "../LayoutPadding";
import { useTranslation } from 'react-i18next';
import { getCoefficient } from "../../services/calculate";


function RecursionFormTemplate(props) {
  const { i18n: {language} } = useTranslation();
  const [checkedItems, setCheckedItems] = useState([]);
  const data = props.data.answers.filter(x => x.title[language] === checkedItems[0]);

  const handleCheck = (title) => {
    if (checkedItems.includes(title)) {
      let arr = [...checkedItems]
      arr.splice(arr.indexOf(title), 1)
      setCheckedItems(arr)
    } else {
      if (!props.data.multipleTitle) {
        setCheckedItems([title])
        return
      }
      if(Object.keys(data[0] || {}).includes("answers")) {
        setCheckedItems([title])
        return
      }
      if (props.data.answers.filter(x => x.title[language] === title)[0].answers) {
        setCheckedItems([title])
        return
      }
      setCheckedItems([...checkedItems, title]);
    }
  }

  const handleAll = () => {
    if (checkedItems.length === props.data.answers.length) {
      setCheckedItems([]);
      return
    }
    let arr = []
    props.data.answers.forEach((answer) => {
      arr = [...arr, answer.title[language]]
    })
    setCheckedItems(arr);
  }
  
  return (
    <div className="border-t-2">
      <LayoutPaddingVerticalTemplate size="default">
        <LayoutSpacerVerticalTemplate size="small">
          {props.data.answers.map((item, index) => (
            <label className="flex items-center gap-6 text-sm md:text-base w-fit" key={index}>
              <CheckboxTemplate checked={checkedItems.includes(item.title[language])} onChange={() => handleCheck(item.title[language])} />
              {item.title[language]}
            </label>
          ))}
          {props.data.multipleTitle &&
            <label className="flex items-center gap-6 text-sm md:text-base w-fit">
              <CheckboxTemplate checked={checkedItems.length === props.data.answers.length} onChange={handleAll} />
              {props.data.multipleTitle[language]}
            </label>
          }
        </LayoutSpacerVerticalTemplate>
      </LayoutPaddingVerticalTemplate>
      
      {(Object.keys(data[0] || {}).includes("coefficient") ||
        (Object.keys(data[0] || {}).includes("groupCoefficient") && checkedItems.length > 1))&& (
          <CalculateFormTemplate
            coefficient={getCoefficient(props.data.answers.map((ans) =>
              {
                if (ans.coefficient) {
                  return ({title: ans.title[language], coefficient: ans.coefficient})
                }
                return ({title: ans.title[language], coefficient: ans.groupCoefficient})
              }), checkedItems)}
            monthly={props.monthly}
          />
      )}

      {(Object.keys(data[0] || {}).includes("answers") && checkedItems.length === 1) && <RecursionFormTemplate data={data[0]} monthly={props.monthly} key={data[0].title[language]}/> }
    </div>
  );
};

export default RecursionFormTemplate;
