import cx from 'classnames';

export const ButtonTemplate = (props) => {
  const container = cx(
    "w-fit whitespace-nowrap hover:cursor-pointer inline-block",
    {
      "bg-[#257CF7] rounded-xl text-white" : !props.disabled,
      "text-sm lg:text-base px-6 py-3 lg:px-8 lg:py-4": props.size === "default",
      "text-xl px-10 py-5": props.size === "large",
      "text-xs px-6 py-3 md:text-base md:px-8": props.size === "small",
    }
  );

  return (
    <span className={container}>{props.title}</span>
  )
};
