import { useParams } from 'react-router-dom';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { BoxShadowTemplate } from '../../templates/BoxShadow';
import { SectionTitleTemplate } from '../../templates/Section/Title';
import RecursionFormTemplate from '../../templates/RecursionForm';
import CalculateFormTemplate from '../../templates/CalculateForm';
import { BlockTitleTemplate } from '../../templates/Block/Title';
import data from '../../../data.json';
import calcData from  '../../../calcData.json';
import { useTranslation } from 'react-i18next';

function LandingProductCalculations() {
  const { t, i18n: {language} } = useTranslation("translation", {keyPrefix: "calculator"});
  const { productId } = useParams();
  const productData = calcData.filter((calc) => calc.service === productId)[0];
  const item = data.filter((item) => item.id === productId)[0];

  return (
    <div className="p-3 py-12 bg-[#F4F4F4]">
      <LayoutContainerHorizontalTemplate>
        <LayoutPaddingHorizontalTemplate size="xlarge">
          <LayoutSpacerVerticalTemplate size="large">
            <SectionTitleTemplate title={t("sectionTitle")} />

            <BoxShadowTemplate className="rounded-xl">
              <div className="border-2 p-6 relative overflow-clip rounded-xl bg-white">
                <BlockTitleTemplate
                  title={item.title[language]}
                  size="large"
                  className="text=[#252525] text-center"
                />
                {productId === "s19" && <p className='p-6 text-center italic'>{t("s19")}</p>}
                {productData && productData.answers && <RecursionFormTemplate data={productData} monthly={productData.monthly} key={productData.service}/>}
                {productData && productData.coefficient && <CalculateFormTemplate coefficient={productData.coefficient} />}
              </div>
            </BoxShadowTemplate>
          </LayoutSpacerVerticalTemplate>
        </LayoutPaddingHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingProductCalculations; 
