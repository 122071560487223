import { BlockTitleTemplate } from '../../templates/Block/Title';
import { ButtonTemplate } from '../../templates/Button';
import { CarouselCustemIndicatorTemplate } from '../../templates/Carousel/CarouselCustomIndicator';
import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingVerticalTemplate } from '../../templates/LayoutPadding';
import { LayoutSpacerHorizontalTemplate, LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import data from '../../../data.json';
import { ImageHandlerTemplate } from '../../templates/ImageHandler';

function LandingBanner() {
  const { t, i18n: {language} } = useTranslation("translation", {keyPrefix: "main.banner"});
  const { locale } = useParams();
  const code = locale ? locale + "/" : "";

  return (
    <div className="p-3 py-12 bg-[#F4F4F4]">
      <LayoutContainerHorizontalTemplate>
        <CarouselCustemIndicatorTemplate length={5}>
          {data.slice(0, 5).map((item, index) =>
            <div className="flex gap-3 bg-[#D8D6E8] rounded-xl h-full overflow-hidden">
              <LayoutPaddingVerticalTemplate key={index} size="small" className="">
                <div className="my-auto pl-3 sm:pl-6 lg:pl-28">
                  <LayoutSpacerHorizontalTemplate size="small" className="flex justify-between z-20">
                    <div className='flex gap-3 justify-between py-3 lg:py-12 w-full'>
                      <LayoutSpacerVerticalTemplate size="default" className="z-10 items-stretch">
                        <div className='flex justify-between items-center'>
                          <BlockTitleTemplate
                            title={item.title[language]}
                            size="large"
                            className="text=[#252525]"
                          />
                          <ImageHandlerTemplate id={item.id} className="max-h-24 sm:h-0"/>
                        </div>
                        <div className="text-sm md:text-base text-left text-[#8C8C8C]">{item.subTitle[language].slice(0, 200).trim()}{item.subTitle[language].length > 200 && <span>...</span>}</div>
                        <Link to={`/${code}product/${item.id}`} className="mt-auto w-fit self-center md:self-start">
                          <ButtonTemplate title={t("button")} size="default" />
                        </Link>
                      </LayoutSpacerVerticalTemplate>
                      
                    </div>

                  </LayoutSpacerHorizontalTemplate>
                  <div
                    className="absolute w-[850px] h-[850px] rounded-full border-gray-200 opacity-30 bg-black/0 border-8 top-[calc(50%-950px/2+42px)] left-[calc(50%-1050px/2+349px)]"
                    style={{ borderWidth: "150px" }}>
                  </div>
                </div>
              </LayoutPaddingVerticalTemplate>
                <ImageHandlerTemplate id={item.id} className="ml-auto h-0 sm:h-96 z-10"/>
            </div>
          )}
        </CarouselCustemIndicatorTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingBanner;
