import cx from 'classnames';

export const BlockTitleTemplate = (props) => {
  const container = cx(
    'font-bold text-left',
    props.className,
    {
      "text-base md:text-2xl": props.size === "large",
      "text-base md:text-xl": props.size === "default",
      "text-xs md:text-lg": props.size === "small",
      "text-sm md:text-base": props.size === "xsmall",
    }
  );

  return (
    <p className={container}>
      <span>{props.title}</span>
    </p>
  );
};

export const BlockSubTitleTemplate = (props) => {
  const container = cx(
    'font-bold text-left',
    props.className,
    {
      "text-base lg:text-xl": props.size === "large",
      "text-sm lg:text-lg": props.size === "default",
      "text-xs lg:text-base": props.size === "small",
    }
  );

  return (
    <p className={container}>
      <span>{props.title}</span>
    </p>
  );
};
