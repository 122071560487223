import cx from 'classnames';

export const LayoutSpacerVerticalTemplate = (props) => {
  const container = cx(
    'flex flex-col',
    props.className,
    {
      'gap-y-3': props.size === 'small',
      'gap-y-6': props.size === 'default',
      'gap-y-12': props.size === 'large',
      'flex-col': !props.reverse,
    }
  );

  return (
    <div className={container}>
      {props.children}
    </div>
  )
};

export const LayoutSpacerHorizontalTemplate = (props) => {
  const container = cx(
    'lg:flex',
    props.className,
    {
      'gap-3': props.size === 'small',
      'gap-6': props.size === 'default',
      'gap-6 lg:gap-12': props.size === 'large',
      'justify-between gap-6 lg:gap-28': props.size === 'xlarge',
      'flex-row': !props.reverse,
      'flex-row-reverse': props.reverse,
    }
  );
  return (
    <div className={container}>
      {props.children}
    </div>
  )
};
