import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutPaddingHorizontalTemplate } from '../../templates/LayoutPadding';
import { LayoutSpacerHorizontalTemplate } from '../../templates/LayoutSpacer';
import SidebarComponent from './Sidebar';
import { NavigationItems } from './NavigationItems';
import LanguagesTemplate from '../../templates/Languages';


function HeaderNavigation() {


  return (
    <div className="bg-[#40A054] fixed inset-x-0 z-30 md:z-auto md:relative">
      <LayoutContainerHorizontalTemplate>
        <LayoutPaddingHorizontalTemplate size="small">
          <div className='md:hidden flex items-center justify-between'>
            <SidebarComponent />
            <LanguagesTemplate />
          </div>
          <div className="hidden md:flex">
            <LayoutSpacerHorizontalTemplate size="large" className="flex">
              <NavigationItems type="header" />
            </LayoutSpacerHorizontalTemplate>
          </div>
        </LayoutPaddingHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default HeaderNavigation;
