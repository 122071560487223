import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutSpacerHorizontalTemplate, LayoutSpacerVerticalTemplate } from '../../templates/LayoutSpacer';
import { SectionTitleTemplate } from '../../templates/Section/Title';
import statClients from '../../assets/stat-clients.png';
import statGroup from '../../assets/stat-group.png';
import statMap from '../../assets/stat-map.png';
import statTrophy from '../../assets/stat-trophy.png';
import { BlockSubTitleTemplate } from '../../templates/Block/Title';
import { useTranslation } from 'react-i18next';

function LandingInfo() {
  const { t } = useTranslation("translation", {keyPrefix: "main.stats"});
  return (
    <div className="p-3 py-12">
      <LayoutContainerHorizontalTemplate>
        <LayoutSpacerVerticalTemplate size="large">
          <SectionTitleTemplate title={t("title")} />

          <LayoutSpacerHorizontalTemplate size="small" className="flex justify-center">
            <LayoutSpacerVerticalTemplate size="default" className="items-center">
              <div className="relative bg-[#F2F4F5] h-16 w-16 lg:h-28 lg:w-28 rounded-full">
                <img className="absolute h-8 left-4 top-4 lg:h-12 lg:left-8 lg:top-8" src={statClients} alt="logo client" />
              </div>
              <BlockSubTitleTemplate title={t("item1")} size="small" className="sm:px-3 text-[#292929] text-center"/>
            </LayoutSpacerVerticalTemplate>

            <LayoutSpacerVerticalTemplate size="default" className="items-center">
              <div className="relative bg-[#F2F4F5] h-16 w-16 lg:h-28 lg:w-28 rounded-full">
                <img className="absolute h-8 left-4 top-4 lg:h-12 lg:left-8 lg:top-8" src={statMap} alt="logo map" />
              </div>
              <BlockSubTitleTemplate title={t("item2")} size="small" className="sm:px-3 text-[#292929] text-center"/>
            </LayoutSpacerVerticalTemplate>

            <LayoutSpacerVerticalTemplate size="default" className="items-center">
              <div className="relative bg-[#F2F4F5] h-16 w-16 lg:h-28 lg:w-28 rounded-full">
                <img className="absolute h-8 left-4 top-4 lg:h-12 lg:left-8 lg:top-8" src={statTrophy} alt="logo trophy" />
              </div>
              <BlockSubTitleTemplate title={t("item3")} size="small" className="sm:px-3 text-[#292929] text-center"/>
            </LayoutSpacerVerticalTemplate>

            <LayoutSpacerVerticalTemplate size="default" className="items-center">
              <div className="relative bg-[#F2F4F5] h-16 w-16 lg:h-28 lg:w-28 rounded-full">
                <img className="absolute h-8 left-4 top-4 lg:h-12 lg:left-8 lg:top-8" src={statGroup} alt="logo group" />
              </div>
              <BlockSubTitleTemplate title={t("item4")} size="small" className="sm:px-3 text-[#292929] text-center"/>
            </LayoutSpacerVerticalTemplate>
          </LayoutSpacerHorizontalTemplate>
        </LayoutSpacerVerticalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default LandingInfo;
