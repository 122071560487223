import { LayoutContainerHorizontalTemplate } from '../../templates/LayoutContainer';
import { LayoutSpacerHorizontalTemplate } from '../../templates/LayoutSpacer';
import LanguagesTemplate from '../../templates/Languages';
import { Info } from './Info';

function HeaderInfo() {
  return (
    <div className="p-3 bg-[#F4F4F4] hidden md:flex">
      <LayoutContainerHorizontalTemplate>
        <LayoutSpacerHorizontalTemplate size="default" className="flex">
          <Info />
          <div className="ml-auto">
            <LanguagesTemplate/>
          </div>
        </LayoutSpacerHorizontalTemplate>
      </LayoutContainerHorizontalTemplate>
    </div>
  );
}

export default HeaderInfo;
